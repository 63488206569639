import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient } from '@tanstack/react-query';
import { PersistQueryClientProvider, Persister } from '@tanstack/react-query-persist-client';
import * as Sentry from '@sentry/react';
import Root from 'Root';
import MaintenancePage from 'pages/MaintenancePage';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

const maintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true';
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24,
    },
  },
});

let persister: Persister;
if (typeof window !== 'undefined') {
  persister = createSyncStoragePersister({
    storage: window.sessionStorage,
  });
}

const authToken = process.env.REACT_APP_SENTRY_AUTH_TOKEN;
const fullDsn = `${process.env.REACT_APP_SENTRY_DNS}?auth_token=${authToken}`;
Sentry.init({
  dsn: fullDsn,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: ['https://portal.catapult-analytics.com/api/permissions'],
      networkRequestHeaders: ['X-Custom-Header'],
      networkResponseHeaders: ['X-Custom-Header'],
    }),
  ],
  environment: process.env.NODE_ENV,

  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', process.env.REACT_APP_BASE_URL],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

root.render(
  <React.StrictMode>
    {maintenanceMode ? (
      <MaintenancePage />
    ) : (
      <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
        <Root />
      </PersistQueryClientProvider>
    )}
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
