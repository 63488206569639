/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import useCookies from 'hooks/useCookies';

interface NotFoundPageProps {
  status?: string;
  mainText?: string;
  secondaryText?: string;
}
const NotFoundPage: React.FC<NotFoundPageProps> = ({
  mainText = 'Page not found',
  status = '404',
  secondaryText = 'Sorry, we couldn’t find the page you’re looking for.',
}) => {
  const { removeErrors, token } = useCookies();
  return (
    <main className="grid h-[100vh] place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-base font-semibold text-primary">{status}</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          {mainText ===
          "<class 'authlib.integrations.base_client.errors.MismatchingStateError'>"
            ? ''
            : mainText}
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600">
          {mainText ===
          "<class 'authlib.integrations.base_client.errors.MismatchingStateError'>"
            ? 'It seems there’s been a hiccup. Please click the button below to go back home and start fresh'
            : secondaryText}
        </p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <button
            type="button"
            className="rounded-md bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm border hover:bg-white hover:text-primary hover:border-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
            onClick={() => {
              removeErrors();
              if (token) {
                window.location.href = `${process.env.REACT_APP_BASE_URL}logout?token=${token}`;
              } else {
                window.location.href = '/';
              }
            }}
          >
            Go back home
          </button>
        </div>
      </div>
    </main>
  );
};

export default NotFoundPage;
